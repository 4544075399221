import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AcceptPreOrderDownPaymentUseCase } from 'src/app/core/usecases/bulk-preorder/accept-pre-order-down-payment.usecase';
import { WalletMoneyNotEnoughDialogComponent } from 'src/app/presentation/pre-order-requests/pre-order-incoming-request-card/wallet-money-not-enough-dialog/wallet-money-not-enough-dialog.component';
import {
  NO_ENOUGH_MONEY_FOR_PRE_ORDER,
  PRE_ORDER_ERRORS_MAP,
} from 'src/app/presentation/shared/constants/builk-pre-order-v3-error-codes';

@Component({
  selector: 'app-accept-pre-order-offer-dialog',
  templateUrl: './accept-pre-order-offer-dialog.component.html',
  styleUrls: ['./accept-pre-order-offer-dialog.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class AcceptPreOrderOfferDialogComponent {
  quantity: number;

  depositRatio: number;

  public productLockingPrice: number;

  public currency: number;

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<AcceptPreOrderOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      quantity: number;
      depositRatio: number;
      requestId: string;
      productLockingPrice: number;
      currency: number;
    },
    private _acceptPreOrderDownPaymentUseCase: AcceptPreOrderDownPaymentUseCase,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.quantity = this._data.quantity;
    this.depositRatio = this._data.depositRatio;
    this.productLockingPrice = this._data.productLockingPrice;
    this.currency = this._data.currency;
  }

  onConfirm() {
    this._acceptPreOrderDownPaymentUseCase.execute(this._data.requestId).subscribe({
      next: () => {
        this._toastrService.success(
          this._translateService.instant('CONFIRM_PRE_ORDER_OFFER_DIALOG.CONFIRMED'),
        );
      },
      error: (err) => {
        const errorCode = err?.error?.errorCode;
        const errorMappedMessage =
          PRE_ORDER_ERRORS_MAP.get(errorCode) || 'PRE_ORDER_DIALOG.ERRORS.GENERAL_ERROR';
        this._toastrService.error(this._translateService.instant(errorMappedMessage));
        if (errorCode === NO_ENOUGH_MONEY_FOR_PRE_ORDER) {
          this._dialog.open(WalletMoneyNotEnoughDialogComponent, {
            width: '490px',
            data: {
              depositRatio: this.depositRatio,
              productLockingPrice: this.productLockingPrice,
              currency: this.currency,
            },
          });
          this.onDismiss();
        }
      },
      complete: () => {
        this._dialogRef.close();
      },
    });
  }

  onDismiss(): void {
    this._dialogRef.close();
  }
}
