<div class="confirm-dialog-wrapper">
  <div class="close" (click)="onDismiss()"><img src="/assets/img/close.svg" /></div>
  <h2 class="heading2--bold">{{ 'REJECT_PRE_ORDER_OFFER_DIALOG.TITLE' | translate }}</h2>
  <p class="dialog-content body1--medium">{{ 'REJECT_PRE_ORDER_OFFER_DIALOG.TEXT' | translate }}</p>
  <div class="button-row">
    <button class="btn btn-cancel body2--medium" (click)="onDismiss()">
      {{ 'REJECT_PRE_ORDER_OFFER_DIALOG.CANCEL' | translate }}
    </button>
    <button class="btn btn-proceed body2--medium" type="submit" (click)="onConfirm()">
      {{ 'REJECT_PRE_ORDER_OFFER_DIALOG.CONFIRM' | translate }}
    </button>
  </div>
</div>
