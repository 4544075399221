import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { PreOrderRequestModel } from 'src/app/core/domain/bulk-pre-order/pre-order-request.model';
import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetBulkPreOrderRequestsUseCase } from 'src/app/core/usecases/bulk-preorder/get-bulk-pre-order-requests.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { SORT_BY_INTRODUCED_AT } from '../../shared/constants/category-products';
import { VIEW_PREORDER_REQUESTS_PAGE } from '../../shared/constants/mixpanel';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { PreOrderIncomingRequestCardComponent } from '../pre-order-incoming-request-card/pre-order-incoming-request-card.component';
import { PreOrderRequestCardComponent } from '../pre-order-request-card/pre-order-request-card.component';
import { PreOrderRulesDialogComponent } from '../pre-order-rules-dialog/pre-order-rules-dialog.component';

@Component({
  selector: 'app-pre-order-requests',
  templateUrl: './pre-order-requests.component.html',
  styleUrls: ['./pre-order-requests.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    NgFor,
    PreOrderRequestCardComponent,
    PreOrderIncomingRequestCardComponent,
    TranslateModule,
    MatDialogModule,
  ],
})
export class PreOrderRequestsComponent implements OnInit {
  public requests: PreOrderRequestModel[];

  public normalRequests: PreOrderRequestModel[];

  public downPaymentRequests: PreOrderRequestModel[];

  public loading = true;

  public noResultsFoundImageSrc = 'assets/img/product-quality.svg';

  public requestsIncludeDownPayment = false;

  constructor(
    private _router: Router,
    private _getBulkPreOrderRequestsUseCase: GetBulkPreOrderRequestsUseCase,
    private _dialog: MatDialog,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _siteTranslateService: SiteTranslateService,
  ) {}

  ngOnInit(): void {
    this.getPreOrderRequests();
  }

  getPreOrderRequests(): void {
    this._getBulkPreOrderRequestsUseCase
      .execute()
      .pipe(
        finalize(() => {
          this.loading = false;
          this._logMixpanelEventUseCase.execute({ eventName: VIEW_PREORDER_REQUESTS_PAGE });
        }),
      )
      .subscribe({
        next: (data) => {
          this.requests = data;
          this.normalRequests = this.requests.filter(
            (request) => request.status !== PreOrderStatuses.DOWN_PAYMENT_OFFERED,
          );

          this.downPaymentRequests = this.requests.filter(
            (request) => request.status === PreOrderStatuses.DOWN_PAYMENT_OFFERED,
          );
          this.requestsIncludeDownPayment = this.requests
            .map((request) => request.status)
            .includes(PreOrderStatuses.DOWN_PAYMENT_OFFERED);
        },
      });
  }

  openPreorderableProducts(): void {
    const queryString = {
      q: '',
      category: '',
      preorderablProducts: true,
      currentPage: 1,
      items: 12,
      sorting: SORT_BY_INTRODUCED_AT,
    };
    this._router.navigate([this._appURLs.CATEGORY_PRODUCTS_URL], { queryParams: queryString });
  }

  displayRules(): void {
    this._dialog.open(PreOrderRulesDialogComponent, {
      width: '905px',
      height: '700px',
      direction: this._siteTranslateService.currentDir,
    });
  }
}
