<div class="pre-order-request-card">
  <div class="pre-order-request-card__details">
    <img
      class="clickable"
      (click)="onProductCardClick()"
      src="{{ request.productPicture || 'assets/img/no-product-image-placeholder.svg' }}"
    />
    <div class="card-data">
      <div class="request-card-header">
        <div class="product-details">
          <p
            [title]="!request.prodId ? request.externalProduct?.productLink : request.productName"
            class="content-main-color body1--bold clickable"
            (click)="onProductCardClick()"
          >
            {{ request.productName || readMoreChars(request.externalProduct?.productLink, 70) }}
          </p>
          <p class="content-medium-color body2--medium">
            {{ request.prodId || localizedValue(request.externalProduct?.productCategory) }}
          </p>
        </div>
        <p
          class="request-status body2--medium"
          [ngClass]="{
            'inProgress-status': request.status === inProgressStatus || request.status === startedStatus,
            'complete-status': request.status === completedStatus,
            'incomplete-status':
              request.status === incompleteStatus || request.status === rejectedStatus || request.status === merchantRejectedOfferStatus,
            'awaiting-status': request.status === awaitingApprovalStatus,
            'money-transfer-approved': request.status === moneyTransferApprovedStatus,
          }"
        >
          {{ mappedRequestStatus | translate }}
        </p>
      </div>
      <div class="request-data">
        <p class="request-creation-date">
          <span class="content-medium-color caption2--medium">
            {{ 'PRE_ORDER_PAGE.REQUEST_DATE' | translate }}
          </span>
          <span class="value body2--bold">
            <i class="icon icon-clock"></i>
            {{ request.createdAt | date : dateFormat }}
          </span>
        </p>
        <p
          class="request-creation-days"
          *ngIf="request.status === inProgressStatus || request.status === awaitingApprovalStatus"
        >
          <span class="content-medium-color caption2--medium">{{
            'PRE_ORDER_PAGE.REMAINING_DAYS' | translate
          }}</span>
          <span class="value body2--bold" *ngIf="request.status === inProgressStatus">
            <i class="icon icon-clock"></i> {{ request.remainingDays }}
            {{ 'PRE_ORDER_PAGE.DAYS' | translate }}
          </span>
          <span class="value body2--bold" *ngIf="request.status === awaitingApprovalStatus">
            <i class="icon icon-clock"></i> -
          </span>
        </p>
        <p class="request-reserved-amount">
          <span class="content-medium-color caption2--medium">{{
            'PRE_ORDER_PAGE.AMOUNT_ON_HOLD' | translate
          }}</span>
          <span class="value body2--bold">
            <i class="icon icon-money-sign-dollar"></i>
            {{ request.lockedWalletAmount }}
            {{ currency | currencyTranslate | translate | currencyShortName }}
          </span>
        </p>
      </div>
    </div>
  </div>
  <div
    class="pre-order-request-card__progress"
    *ngIf="!(request.status === rejectedStatus || request.status === merchantRejectedOfferStatus)"
  >
    <div
      [ngClass]="
        request.status === inProgressStatus || request.status === awaitingApprovalStatus
          ? 'dark-blue-text'
          : 'light-text'
      "
    >
      <div class="request-percentage">
        <p class="body2--bold">{{ 'PRE_ORDER_PAGE.QUANTITY_SOLD_RESERVED' | translate }}</p>
        <p class="percentage heading2--bold">
          {{ (request.fulfilledQuantity / request.requestedQuantity) * 100 }}%
        </p>
      </div>
      <div class="request-progress-bar">
        <mat-progress-bar
          mode="determinate"
          [value]="(request.fulfilledQuantity / request.requestedQuantity) * 100"
        ></mat-progress-bar>
      </div>
      <div class="request-quantities">
        <p>
          {{ request.fulfilledQuantity }} <span>{{ 'PRE_ORDER_PAGE.SOLD' | translate }}</span>
        </p>
        <p>
          {{ request.requestedQuantity }} <span>{{ 'PRE_ORDER_PAGE.RESERVED' | translate }}</span>
        </p>
      </div>
    </div>
  </div>
  <p *ngIf="request.rejectionReason" class="rejection-reason">{{ request.rejectionReason }}</p>
</div>
