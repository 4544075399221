import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RejectPreOrderDownPaymentUseCase } from 'src/app/core/usecases/bulk-preorder/reject-pre-order-down-payment.usecase';
import { PRE_ORDER_ERRORS_MAP } from 'src/app/presentation/shared/constants/builk-pre-order-v3-error-codes';

@Component({
  selector: 'app-reject-pre-order-offer-dialog',
  templateUrl: './reject-pre-order-offer-dialog.component.html',
  styleUrls: ['./reject-pre-order-offer-dialog.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class RejectPreOrderOfferDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<RejectPreOrderOfferDialogComponent>,
    private _rejectPreOrderDownPaymentUseCase: RejectPreOrderDownPaymentUseCase,
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      requestId: string;
    },
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) {}

  onConfirm() {
    this._rejectPreOrderDownPaymentUseCase.execute(this._data.requestId).subscribe({
      next: () => {
        this._dialogRef.close();
      },
      error: (err) => {
        const errorCode = err?.error?.errorCode;
        const errorMappedMessage =
          PRE_ORDER_ERRORS_MAP.get(errorCode) || 'PRE_ORDER_DIALOG.ERRORS.GENERAL_ERROR';
        this._toastrService.error(this._translateService.instant(errorMappedMessage));
      },
    });
  }

  onDismiss(): void {
    this._dialogRef.close();
  }
}
