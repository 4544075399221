import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';

@Component({
  selector: 'app-wallet-money-not-enough-dialog',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './wallet-money-not-enough-dialog.component.html',
  styleUrls: ['./wallet-money-not-enough-dialog.component.scss'],
})
export class WalletMoneyNotEnoughDialogComponent {
  public depositRatio: number;

  public productLockingPrice: number;

  public currency: number;

  constructor(
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
    private _dialogRef: MatDialogRef<WalletMoneyNotEnoughDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      depositRatio: number;
      productLockingPrice: number;
      currency: number;
    },
  ) {}

  ngOnInit(): void {
    this.depositRatio = this._data.depositRatio;
    this.productLockingPrice = this._data.productLockingPrice;
    this.currency = this._data.currency;
  }

  onDismiss(): void {
    this._dialogRef.close();
  }

  goToWallet(): void {
    this._router.navigate([this.appURLs.WALLET_URL]);
    this.onDismiss();
  }
}
