import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { provideRouter } from '@angular/router';
import { PreOrderRequestCardComponent } from './pre-order-request-card/pre-order-request-card.component';
import { PreOrderRequestsComponent } from './pre-order-requests/pre-order-requests.component';
import { PreOrderRulesDialogComponent } from './pre-order-rules-dialog/pre-order-rules-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    PreOrderRequestsComponent,
    PreOrderRequestCardComponent,
    PreOrderRulesDialogComponent,
  ],
  providers: [provideRouter([{ path: '', component: PreOrderRequestsComponent }])],
})
export class PreOrderRequestsModule {}
