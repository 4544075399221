<div class="not-enough-money-container">
  <img class="close" (click)="onDismiss()" src="/assets/img/close.svg" />
  <p class="heading2--bold content-main-color">
    {{ 'PRE_ORDER_PAGE.NOT_ENOUGH_MONEY_DIALOG.TITLE' | translate }}
  </p>
  <p class="body1--medium content-medium-color">
    {{
      'PRE_ORDER_PAGE.NOT_ENOUGH_MONEY_DIALOG.DESCRIPTION'
        | translate : { productLockingPrice, depositRatio, currency }
    }}
  </p>
  <button class="go-to-wallet-button body2--medium" (click)="goToWallet()">
    {{ 'PRE_ORDER_PAGE.NOT_ENOUGH_MONEY_DIALOG.GO_TO_WALLET' | translate }}
  </button>
</div>
