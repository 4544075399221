<section class="pre-order-requests">
  <div class="pre-order-requests__title-wrapper">
    <h4 class="pre-order-requests__title">
      {{ 'PRE_ORDER_PAGE.TITLE' | translate }}
    </h4>
    <button class="btn btn-rules" (click)="displayRules()">
      {{ 'PRE_ORDER_PAGE.DISPLAY_RULES_BUTTON' | translate }}
    </button>
  </div>
  <loader [loading]="loading"></loader>
  <p class="pre-order-requests__description" *ngIf="requests?.length > 0">
    {{ 'PRE_ORDER_PAGE.PRE_ORDER_REQUESTS_NOTE' | translate }}
  </p>
  <div class="pre-order-requests__wrapper" *ngIf="!loading">
    <ng-container *ngIf="requests?.length > 0; else noResultsFound">
      <div class="requests-cards-container">
        <div *ngIf="requestsIncludeDownPayment">
          <p class="body1--bold content-main-color">
            {{ 'PRE_ORDER_PAGE.NEXT_REQUESTS' | translate }}({{ downPaymentRequests.length }})
          </p>
          <app-pre-order-incoming-request-card
            *ngFor="let downPaymentRequest of downPaymentRequests"
            [request]="downPaymentRequest"
            (statusUpdated)="getPreOrderRequests()"
          ></app-pre-order-incoming-request-card>
        </div>
        <div *ngIf="normalRequests?.length > 0">
          <p class="body1--bold content-main-color">
            {{ 'PRE_ORDER_PAGE.ALL_REQUESTS' | translate }}
          </p>
          <app-pre-order-request-card
            *ngFor="let normalRequest of normalRequests"
            [request]="normalRequest"
          ></app-pre-order-request-card>
        </div>
      </div>
    </ng-container>
    <ng-template #noResultsFound>
      <div class="pre-order-requests__wrapper__no-results">
        <div class="image-container">
          <img [src]="noResultsFoundImageSrc" />
        </div>
        <div class="text">
          <p class="title">{{ 'PRE_ORDER_PAGE.NO_REQUESTS_TITLE' | translate }}</p>
          <p class="description">{{ 'PRE_ORDER_PAGE.NO_REQUESTS_DESCRIPTION' | translate }}</p>
        </div>
        <div class="button-container">
          <button class="btn btn-products" (click)="openPreorderableProducts()">
            {{ 'PRE_ORDER_PAGE.SHOW_PRODUCTS' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</section>
