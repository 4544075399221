import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { readMoreChars } from '@mongez/reinforcements';
import { TranslateModule } from '@ngx-translate/core';
import { navigateTo } from '@presentation/shared/router';
import { openInNewTab } from '@presentation/shared/utils';
import { PreOrderRequestModel } from 'src/app/core/domain/bulk-pre-order/pre-order-request.model';
import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { LocalizedComponent } from '../../base/localized.component';
import { dateFormat, PRODUCT_DETAILS } from '../../shared/constants';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { MultitenancyService } from '../../shared/services/multitenancy.service';
import { LocalizedPreOrderStatusesMapper } from '../pre-order-request-statuses';

@Component({
  selector: 'app-pre-order-request-card',
  templateUrl: './pre-order-request-card.component.html',
  styleUrls: ['./pre-order-request-card.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatProgressBarModule,
    DatePipe,
    TranslateModule,
    CurrencyTranslatePipe,
    CurrencyShortNamePipe,
  ],
})
export class PreOrderRequestCardComponent extends LocalizedComponent implements OnInit {
  @Input() request: PreOrderRequestModel;

  public inProgressStatus = PreOrderStatuses.INPROGRESS;

  public completedStatus = PreOrderStatuses.COMPLETED;

  public incompleteStatus = PreOrderStatuses.INCOMPLETE;

  public rejectedStatus = PreOrderStatuses.REJECTED;

  public awaitingApprovalStatus = PreOrderStatuses.AWAITING;

  public moneyTransferApprovedStatus = PreOrderStatuses.MONEY_TRANSFERRED_APPROVED;

  public merchantRejectedOfferStatus = PreOrderStatuses.DOWN_PAYMENT_REJECTED_BY_MERCHANT;

  public startedStatus = PreOrderStatuses.STARTED;

  public dateFormat = dateFormat;

  public currency: string;

  public productDetailsUrls = PRODUCT_DETAILS;

  public readMoreChars = readMoreChars;

  public mappedRequestStatus: string;

  constructor(
    private _multitenancyService: MultitenancyService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this.currency = this._multitenancyService.getCurrentCountry().currency.arabicName;
    this.mappedRequestStatus = LocalizedPreOrderStatusesMapper(this.request.status);
  }

  onProductCardClick(): void {
    if (this.request.productObjectId) {
      navigateTo(`${this.productDetailsUrls}/${this.request.productObjectId}`);
    } else if (this.request.externalProduct?.productLink) {
      openInNewTab(this.request.externalProduct.productLink);
    }

    this._logMixpanelEventUseCase.execute({
      eventName: 'pre_order_card_product_clicked',
    });
  }
}
